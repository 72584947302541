import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../contexts/ThemeProvider";
import { IoSunny } from "react-icons/io5";
import { FaMoon } from "react-icons/fa";
import { motion } from "framer-motion";

const Nav = () => {
  const { toggleTheme, theme } = useContext(ThemeContext);

  let audio = new Audio("/sounds/click.mp3");

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [theme]);

  return (
    <motion.nav
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 1, delay: 1.6 }}
    >
      <div className="container">
        <Link to="/">anthony collette</Link>
        <ul>
          <li>
            <Link
              to="https://www.linkedin.com/in/graphisteind%C3%A9pendant/"
              className="icon-link"
              target="_blank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                <path d="M25,2C12.318,2,2,12.317,2,25s10.318,23,23,23s23-10.317,23-23S37.682,2,25,2z M18,35h-4V20h4V35z M16,17 c-1.105,0-2-0.895-2-2c0-1.105,0.895-2,2-2s2,0.895,2,2C18,16.105,17.105,17,16,17z M37,35h-4v-5v-2.5c0-1.925-1.575-3.5-3.5-3.5 S26,25.575,26,27.5V35h-4V20h4v1.816C27.168,20.694,28.752,20,30.5,20c3.59,0,6.5,2.91,6.5,6.5V35z" />
              </svg>{" "}
              Linkedin
            </Link>
          </li>
          <li>
            <Link
              to="https://github.com/AnthonyCollette"
              className="icon-link"
              target="_blank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
              </svg>{" "}
              Github
            </Link>
          </li>
          {/* <li>
            <div className="switch-toggle--mobile" onClick={() => {
                audio.play()
                toggleTheme()
            }}>
              <label className="label-none" htmlFor="toggleTheme">
                Changer de theme
              </label>
              {theme === "dark" ? (<>
                <FaMoon />
                <input
                  type="checkbox"
                  id="toggleTheme"
                  name="toggleTheme"
                  checked
                ></input></>
              ) : (
                <>
                <input
                  id="toggleTheme"
                  type="checkbox"
                  name="toggleTheme"
                ></input>
                <IoSunny />
              </>
              )}
            </div>
          </li> */}
          <li>
            <IoSunny />
            <div
              className="switch-toggle"
              onClick={() => {
                audio.play();
                toggleTheme();
              }}
            >
              <label className="label-none" htmlFor="toggleTheme">
                Changer de theme
              </label>
              {theme === "dark" ? (
                <input
                  type="checkbox"
                  id="toggleTheme"
                  name="toggleTheme"
                  checked
                ></input>
              ) : (
                <input
                  id="toggleTheme"
                  type="checkbox"
                  name="toggleTheme"
                ></input>
              )}
            </div>
            <FaMoon />
          </li>
          <li className="link-to-blog">
            <Link to="/blog" className="btn btn--primary">
              Blog
            </Link>
          </li>
        </ul>
      </div>
    </motion.nav>
  );
};

export default Nav;
